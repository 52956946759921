.banner {
	height: 100vh;
	background-position: center;
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
}

.content {
	text-align: center;
	z-index: 500;
}

.content h1 {
	color: #fff;
	font-size: 42px;
}

.content h2 {
	color: #fff;
	font-size: 30px;
}

.content p {
	color: #fff;
	margin-bottom: 30px;
}

.content .button {
	padding: 10px 30px;
	background-color: rgb(241, 97, 45);
	border: none;
	font-size: 20px;
	border-radius: 5px;
	text-transform: uppercase;
	text-decoration: none;
	color: #fff;
}
